function wrapText(context, text, x, y, maxWidth, lineHeight) {
  const words = text.split(' ');
  let line = '';

  words.forEach((word, i) => {
    const testLine = line + word + ' ';
    const metrics = context.measureText(testLine);
    const testWidth = metrics.width;

    if (testWidth > 90 && i > 0) {
      context.fillText(line, x, y);
      line = `${word} `;
      y += lineHeight;
    } else {
      line = testLine;
    }
  });

  context.fillText(line, x, y);
}

function createRectangle(ctx, rectX) {
  ctx.fillStyle = 'white';
  ctx.beginPath();
  ctx.roundRect(rectX, 65, 110, 75, [8]);
  ctx.fill();
}

export default function generateSingleWalletImage(template, data) {
  return new Promise((resolve) => {
    const canvasElement = document.createElement('canvas');
    const ctx = canvasElement.getContext('2d');

    const CANVAS = {
      width: 624,
      height: 240,
    };

    const IMAGE = {
      top: 136,
      maxHeight: 120,
      maxWidth: 104,
      crossOrigin: 'anonymous',
    };

    let img1x;
    let img2x;
    let img3x;
    let img4x;

    let rect1x;
    let rect2x;
    let rect3x;
    let rect4x;

    if (data[3]) {
      img1x = 80;
      img2x = img1x + CANVAS.width / 4;
      img3x = img1x + (2 * CANVAS.width) / 4;
      img4x = img1x + (3 * CANVAS.width) / 4;

      rect1x = 25;
      rect2x = 181;
      rect3x = 337;
      rect4x = 493;
    } else if (data[2]) {
      img1x = 106;
      img2x = img1x + CANVAS.width / 3;
      img3x = img1x + (2 * CANVAS.width) / 3;

      rect1x = 51;
      rect2x = 260;
      rect3x = 467;
    } else if (data[1]) {
      img1x = 200;
      img2x = 400;

      rect1x = 145;
      rect2x = 345;
    }

    const TEXT = {
      font: '12px Campton',
      letterSpacing: '0.2px',
      color: 'white',
      align: 'center',
      lineHeight: 14,
      top: 26,
      maxWidth: 86,
    };

    const templateImg = new Image();
    templateImg.src = template;

    templateImg.onload = function () {
      canvasElement.width = CANVAS.width;
      canvasElement.height = CANVAS.height;

      ctx.drawImage(templateImg, 0, 0, CANVAS.width, CANVAS.height);

      if (!data[0]) {
        resolve('');
      } else {
        const image1 = new Image();
        image1.crossOrigin = IMAGE.crossOrigin;
        image1.src = data[0].picture;
        image1.onload = function () {
          const ratio = Math.min(IMAGE.maxWidth / image1.width, IMAGE.maxHeight / image1.height);
          const img1left = img1x - (image1.width * ratio) / 2;
          const img1top = IMAGE.top - image1.height * ratio;

          createRectangle(ctx, rect1x);

          ctx.drawImage(image1, img1left, img1top, image1.width * ratio, image1.height * ratio);
          // eslint-disable-next-line sonarjs/no-duplicate-string
          ctx.font = TEXT.font;
          ctx.letterSpacing = TEXT.letterSpacing;
          ctx.fillStyle = TEXT.color;
          ctx.textAlign = TEXT.align;
          wrapText(
            ctx,
            data[0].label,
            img1x,
            img1top + image1.height * ratio + TEXT.top,
            TEXT.maxWidth,
            TEXT.lineHeight
          );

          if (!data[1]) {
            const res = canvasElement.toDataURL('image/png');
            resolve(res);
          } else {
            const image2 = new Image();
            image2.crossOrigin = IMAGE.crossOrigin;
            image2.src = data[1].picture;
            image2.onload = function () {
              const ratio = Math.min(
                IMAGE.maxWidth / image2.width,
                IMAGE.maxHeight / image2.height
              );
              const img2left = img2x - (image2.width * ratio) / 2;
              const img2top = IMAGE.top - image2.height * ratio;

              createRectangle(ctx, rect2x);

              ctx.drawImage(image2, img2left, img2top, image2.width * ratio, image2.height * ratio);
              ctx.font = TEXT.font;
              ctx.letterSpacing = TEXT.letterSpacing;
              ctx.fillStyle = TEXT.color;
              ctx.textAlign = TEXT.align;
              wrapText(
                ctx,
                data[1].label,
                img2x,
                img2top + image2.height * ratio + TEXT.top,
                TEXT.maxWidth,
                TEXT.lineHeight
              );

              if (!data[2]) {
                const res = canvasElement.toDataURL('image/png');
                resolve(res);
              } else {
                const image3 = new Image();
                image3.crossOrigin = IMAGE.crossOrigin;
                image3.src = data[2].picture;
                image3.onload = function () {
                  const ratio = Math.min(
                    IMAGE.maxWidth / image3.width,
                    IMAGE.maxHeight / image3.height
                  );
                  const img3left = img3x - (image3.width * ratio) / 2;
                  const img3top = IMAGE.top - image3.height * ratio;

                  createRectangle(ctx, rect3x);

                  ctx.drawImage(
                    image3,
                    img3left,
                    img3top,
                    image3.width * ratio,
                    image3.height * ratio
                  );
                  ctx.font = TEXT.font;
                  ctx.letterSpacing = TEXT.letterSpacing;
                  ctx.fillStyle = TEXT.color;
                  ctx.textAlign = TEXT.align;
                  wrapText(
                    ctx,
                    data[2].label,
                    img3x,
                    img3top + image3.height * ratio + TEXT.top,
                    TEXT.maxWidth,
                    TEXT.lineHeight
                  );

                  if (!data[3]) {
                    const res = canvasElement.toDataURL('image/png');
                    resolve(res);
                  } else {
                    const image4 = new Image();
                    image4.crossOrigin = IMAGE.crossOrigin;
                    image4.src = data[3].picture;
                    image4.onload = function () {
                      const ratio = Math.min(
                        IMAGE.maxWidth / image4.width,
                        IMAGE.maxHeight / image4.height
                      );
                      const img4left = img4x - (image4.width * ratio) / 2;
                      const img4top = IMAGE.top - image4.height * ratio;

                      createRectangle(ctx, rect4x);

                      ctx.drawImage(
                        image4,
                        img4left,
                        img4top,
                        image4.width * ratio,
                        image4.height * ratio
                      );
                      ctx.font = TEXT.font;
                      ctx.letterSpacing = TEXT.letterSpacing;
                      ctx.fillStyle = TEXT.color;
                      ctx.textAlign = TEXT.align;
                      wrapText(
                        ctx,
                        data[3].label,
                        img4x,
                        img4top + image4.height * ratio + TEXT.top,
                        TEXT.maxWidth,
                        TEXT.lineHeight
                      );

                      const res = canvasElement.toDataURL('image/png');
                      resolve(res);
                    };
                  }
                };
              }
            };
          }
        };
      }
    };
  });
}
