export const ADD_TO_WALLET_BUTTON_CLASS = 'add-to-wallet';
export const ADD_TO_WALLET_BUTTON_LOADING_CLASS = 'is-loading';
export const ADD_TO_WALLET_BUTTON_STYLE = `
  .add-to-wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    padding: 13px 15px 14px;
    border: none;
    border-radius: 23px;
    background-color: #171717;
    color: #fff;
    font-size: 10px;
    line-height: 1;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transform: translateX(-50%);
    cursor: pointer;
  }

  &:active,
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }

  .add-to-wallet img {
    margin-right: 15px;
    height: 20px;
  }

  .is-loading::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    background-color: #000;
    opacity: 0.6;
  }
`;
export const ADD_TO_WALLET_BUTTON_TYPE = 'button';

export const ROUTINE_TYPE_MORNING = 'morning';
export const ROUTINE_TYPE_NIGHT = 'night';

export const SERVICE_BUTTON_CLASS = 'button-open-dsf-app';
export const SERVICE_SELECTOR = 'dsf-app[application="skindrv2"]';
export const SERVICE_TRIGGER_URL_PARAM_KEY = 'dsf-app';
