<template>
  <div class="slideshow-navigation">
    <button
      class="slideshow-navigation__button -left"
      :disabled="!showPreviousButton"
      :aria-label="labels.previous"
      @click.prevent="delayedClick('previous')"
    >
      <span class="arrow -left"></span>
    </button>
    <div class="slideshow-navigation__middle">
      <span class="slideshow-navigation__index">{{ currentIndex }}</span>
      <span class="slideshow-navigation__separator">{{ separatorLabel }}</span>
      <span class="slideshow-navigation__index">{{ from }}</span>
    </div>
    <button
      class="slideshow-navigation__button -right"
      :disabled="!showNextButton"
      :aria-label="labels.next"
      @click.prevent="delayedClick('next')"
    >
      <span class="arrow -right"></span>
    </button>
  </div>
</template>

<script>
import { AnalyticsHandler, throttle } from '../../../../../../Foundation/Core/code/Scripts';

const DELAY_TIME_BEFORE_TRANSITIONING = 1500;

export default {
  name: 'SlideshowNavigation',
  props: {
    current: { type: Number, required: false, default: 1 },
    from: { type: Number, required: false, default: 1 },
    separatorLabel: { type: String, required: false, default: '' },
    labels: { type: Object, required: false, default: () => {} },
    currentSlide: { type: String, required: true, default: '' },
  },
  computed: {
    showNextButton() {
      return this.current + 1 < this.from;
    },
    showPreviousButton() {
      return this.current > 0;
    },
    currentIndex() {
      return this.current + 1;
    },
  },
  methods: {
    delayedClick: throttle(function (direction) {
      this.navigationClicked(direction);
    }, DELAY_TIME_BEFORE_TRANSITIONING),

    navigationClicked(direction) {
      AnalyticsHandler.getAnalyticsHandler().push({
        type: 'userActionEvent',
        category: 'beauty mag slider',
        action: direction,
        label: `${this.currentIndex}::${this.currentSlide}`,
      });

      this.$emit('navigationClicked', {
        direction: direction,
      });
    },
  },
};
</script>
