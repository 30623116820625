<template>
  <section
    :class="['slideshow', { 'slideshow--legacy': legacy }, { 'slideshow--immersive': immersive }]"
  >
    <div ref="images" class="slideshow__images">
      <slot name="slideshow-images" />
      <SlideshowNavigation
        v-if="immersive"
        class="slideshow-navigation__test"
        :labels="navigationLabels"
        :current="current"
        :from="from"
        :separator-label="labelOf"
        :current-slide="currentSlideName"
        @navigationClicked="navigationClicked"
      />
    </div>
    <section ref="content" class="slideshow__content">
      <slot name="slideshow-navigation-favorite" />
      <SlideshowNavigation
        v-if="!immersive"
        class="slideshow-navigation__test"
        :labels="navigationLabels"
        :current="current"
        :from="from"
        :separator-label="labelOf"
        :current-slide="currentSlideName"
        @navigationClicked="navigationClicked"
      />
      <slot name="slideshow-copy" />
    </section>
  </section>
</template>

<script>
import SlideshowNavigation from '../slideshowNavigation/slideshow-navigation.vue';
import eventBus from '@loreal/eventbus-js';

const SLIDER_ITEM = '.slider__item';

export default {
  name: 'Slideshow',
  components: {
    SlideshowNavigation,
  },
  props: {
    legacy: { type: Boolean, required: false, default: true },
    immersive: { type: Boolean, required: false, default: false },
    labelPrevious: { type: String, required: false, default: 'Previous' },
    labelNext: { type: String, required: false, default: 'Next' },
    labelOf: { type: String, required: false, default: '' },
    uniqueId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      from: 0,
      current: 0,
      slidesName: [],
    };
  },
  computed: {
    navigationLabels() {
      return {
        next: this.labelNext,
        previous: this.labelPrevious,
      };
    },
    currentSlideName() {
      return this.slidesName[this.current] || '';
    },
  },
  mounted() {
    if (this.$slots['slideshow-images']) {
      this.from = this.$refs.images.querySelectorAll(SLIDER_ITEM).length;
    }

    if (this.$slots['default']) {
      let slides = this.$refs.content.querySelectorAll(SLIDER_ITEM);
      slides = Array.from(slides);
      this.slidesName = slides.map((el) => {
        return el.dataset.name;
      });
    }
    window.addEventListener('resize', this.resizeEvent);
    eventBus.on(`${this.uniqueId}::childMoved`, this.updateChilds);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeEvent);
  },
  methods: {
    updateChilds(payload) {
      const { newIndex, trigger } = payload;
      this.current = newIndex;
      eventBus.emit(`${this.uniqueId}::gotoPage`, {
        index: this.current,
        element: SLIDER_ITEM,
        trigger,
      });
    },
    navigationClicked(payload) {
      const { direction } = payload;

      if (direction === 'next') {
        this.current++;
      } else {
        this.current--;
      }

      eventBus.emit(`${this.uniqueId}::gotoPage`, {
        index: this.current,
        element: SLIDER_ITEM,
        trigger: 'rnd',
      });
    },
    resizeEvent() {
      eventBus.emit(`${this.uniqueId}::gotoPage`, {
        index: this.current,
        element: SLIDER_ITEM,
        trigger: 'rnd',
      });
    },
  },
};
</script>
