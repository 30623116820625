<template>
  <div :class="{ 'oap-product-header__rating': isNewProductDetailPage }" class="starRatings">
    <div
      :class="{ 'oap-product-header__rating-slot': isNewProductDetailPage }"
      @click="anchorToContent"
    >
      <slot />
    </div>
    <template v-if="isNewProductDetailPage">
      <ul class="oap-product-header__list">
        <li class="oap-product-header__list-item">
          <button class="oap-product-header__rating-trigger" @click="anchorToContent()">
            {{ seeReviewsText }}
          </button>
        </li>
        <li class="oap-product-header__list-item">
          <button class="oap-product-header__rating-trigger" @click="writeReview()">
            {{ writeReviewText }}
          </button>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import { AnalyticsHandler } from '@Foundation';

export default {
  name: 'RrStarRatings',

  props: {
    analytics: { type: Object, required: true },
    isNewProductDetailPage: { type: Boolean, default: false },
    seeReviewsText: { type: String, default: 'See All Reviews' },
    writeReviewText: { type: String, default: 'Write Review' },
  },

  settings: {
    reviewsList: '.reviews-container',
    writeReviewBvBtn: '.bv-write-review',
    writeReviewRefAppBtn: '.write-review-btn',
    noReviewBvBtn: '.bv-write-review-label',
  },

  methods: {
    /* istanbul ignore next */
    pushAnalytics(action) {
      AnalyticsHandler.getAnalyticsHandler().push({
        type: 'userActionEvent',
        action,
        category: this.analytics.category,
        label: `${this.analytics.productName}::${this.analytics.productEan}`,
      });
    },
    /* istanbul ignore next */
    anchorToContent() {
      eventBus.emit('bvLoadedEventBus', true);
      setTimeout(() => {
        this.pushAnalytics(this.analytics.actionSeeAll);
        const reviewsList = document.querySelector(this.$options.settings.reviewsList);
        if (reviewsList) {
          window.scrollTo({
            top: reviewsList.offsetTop,
            behavior: 'smooth',
          });
        }
      }, 800);
    },
    /* istanbul ignore next */
    writeReview() {
      this.pushAnalytics(this.analytics.actionWrite);
      const writeReviewBtn =
        document.querySelector(this.$options.settings.writeReviewBvBtn) ||
        document.querySelector(this.$options.settings.writeReviewRefAppBtn) ||
        document.querySelector(this.$options.settings.noReviewBvBtn);
      if (writeReviewBtn) {
        writeReviewBtn.click();
      } else {
        eventBus.emit('bvLoadedEventBus', true);
        setTimeout(() => {
          const writeReviewBtnOnpageLoad =
            document.querySelector(this.$options.settings.writeReviewBvBtn) ||
            document.querySelector(this.$options.settings.writeReviewRefAppBtn) ||
            document.querySelector(this.$options.settings.noReviewBvBtn);
          if (writeReviewBtnOnpageLoad) {
            writeReviewBtnOnpageLoad.click();
          }
        }, 3000);
      }
    },
  },
};
</script>
